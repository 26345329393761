import React from "react"
import { Flex, Text } from "rebass"

export const CenteredContainer = props => (
  <Flex
    sx={{
      flexWrap: "wrap",
      alignContent: "center",
      alignItems: "center",
      textAlign: "center",
      maxWidth: 800,
      width: "100%",
      py: 4,
      px: 3,
    }}
    {...props}
  />
)

export const ImageWrapper = props => (
  <Flex
    sx={{
      justifyContent: "center",
      width: "100%",
      height: "100%",
      svg: {
        display: "block",
      },
    }}
    {...props}
  />
)

export const Heading = props => (
  <Text
    sx={{
      mt: [2, 4],
      fontSize: [5, 6],
      fontWeight: "heading",
      color: "white",
      lineHeight: ["36px", "50px"],
      width: "100%",
    }}
    {...props}
  />
)

export const Description = props => (
  <Text
    sx={{
      mt: 3,
      fontSize: [1, 2],
      width: "100%",
      fontWeight: "body",
      color: "blueSelectSquad",
    }}
    {...props}
  />
)

export const ButtonsWrapper = props => (
  <Flex
    sx={{
      p: 4,
      width: "100%",
      alignItems: "center",
      flexDirection: ["column", "row"],
      justifyContent: "center",
      "& > button": {
        "&:first-of-type": {
          mr: [0, 3],
          mb: [3, 0],
        },
        "&:last-of-type": {
          ml: [0, 3],
        },
      },
    }}
    {...props}
  />
)

export const FooterWrapper = props => (
  <Flex
    sx={{
      position: "absolute",
      bottom: 0,
      left: 0,
      justifyContent: "flex-end",
      alignItems: "center",
      p: 4,
      width: "100%",
      "& > button": {
        minWidth: 180,
        py: "11px",
        "&:first-of-type": {
          mr: 3,
        },
        "&:last-of-type": {
          ml: 3,
        },
      },
    }}
    {...props}
  />
)
