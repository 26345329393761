import BaseLayout from "@components/BaseLayout"
import SEO from "@components/Seo"
import routes from "@utils/routes"
import { navigate } from "gatsby"
import ThankYou from "@components/ThankYou"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { MENU_HEIGHT } from "@components/BaseLayout/Header/Header.styles"
import { Box, Flex } from "rebass"
import sendInformationLottie from "@images/send-information-lottie.json"
import newsletterLottie from "@images/newsletter-lottie.json"
import logoLottie from "@images/x-apps-lottie.json"
import LottieAnimation from "@components/LottieAnimation"
import Translate, { TranslateHTML } from "@components/Translate"
import { useSubscribeToNewsletterForm } from "@utils/hooks/useSubscribeToNewsletterForm"
import { ArrowLeft } from "react-feather"
import { ReactTypeformEmbed } from "react-typeform-embed"
import { Botao } from "@xapps/design-system"

function ObrigadoPage() {
  const queryParams =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : null
  const goToHome = () => navigate(routes.home)
  const { handleSubmit } = useSubscribeToNewsletterForm(
    queryParams?.get("email")
  )
  const { metatag } = useStaticQuery(graphql`
    query Obrigado {
      metatag: markdownRemark(
        fileAbsolutePath: { glob: "**/content/metatags/obrigado.md" }
      ) {
        frontmatter {
          languages {
            language
            keywords
            title
            description
          }
        }
      }
    }
  `)
  const metatags = metatag.frontmatter.languages.find(
    lang => lang.language === process.env.GATSBY_LANGUAGE
  )

  const [step, dispatchStep] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case "newsletter":
          return {
            image: <LottieAnimation animation={newsletterLottie} />,
            heading: <TranslateHTML id="obrigado.newsletter.heading" />,
            description: <TranslateHTML id="obrigado.newsletter.description" />,
            buttons: (
              <>
                <Botao
                  sx={{
                    width: ["100%", "auto"],
                    py: 3,
                  }}
                  onClick={() => {
                    handleSubmit()
                    dispatchStep({ type: "end1" })
                  }}
                >
                  <Translate id="obrigado.newsletter.buttonSend" />
                </Botao>
                <Botao
                  sx={{
                    width: ["100%", "auto"],
                    py: 3,
                  }}
                  variant="terciario"
                  onClick={() => dispatchStep({ type: "end1" })}
                >
                  <Translate id="obrigado.newsletter.buttonSkip" />
                </Botao>
              </>
            ),
          }
        case "end1":
          return {
            image: <LottieAnimation animation={logoLottie} />,
            heading: (
              <Translate
                values={{ name: queryParams.get("name") }}
                id="obrigado.end.heading"
              />
            ),
            description: (
              <TranslateHTML
                values={{ email: queryParams.get("email") }}
                id="obrigado.end.description1"
              />
            ),
            buttons: (
              <Botao
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={goToHome}
              >
                <Flex sx={{ mr: 2 }}>
                  <ArrowLeft />
                </Flex>
                <Translate id="obrigado.end.buttonSend" />
              </Botao>
            ),
          }
        case "end2":
          return {
            image: <LottieAnimation animation={logoLottie} />,
            heading: (
              <Translate
                values={{ name: queryParams.get("name") }}
                id="obrigado.end.heading"
              />
            ),
            description: <TranslateHTML id="obrigado.end.description2" />,
            buttons: (
              <Botao
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={goToHome}
              >
                <Flex sx={{ mr: 2 }}>
                  <ArrowLeft />
                </Flex>
                <Translate id="obrigado.end.buttonSend" />
              </Botao>
            ),
          }
        case "form":
          return {
            image: (
              <Box
                sx={{
                  width: "100%",
                  position: "fixed",
                  height: MENU_HEIGHT.map(
                    height => `calc(var(--app-height) - ${height}px)`
                  ),
                  top: MENU_HEIGHT,
                }}
              >
                <ReactTypeformEmbed
                  style={{
                    position: "relative",
                  }}
                  onSubmit={function () {
                    dispatchStep({ type: "end2" })
                  }}
                  url={`https://xapps.typeform.com/to/SpdaFpAx?nome=${queryParams.get(
                    "name"
                  )}&sobrenome=${queryParams.get(
                    "surname"
                  )}&email=${queryParams.get(
                    "email"
                  )}&telefone=${queryParams.get("phone")}`}
                />
              </Box>
            ),
          }
        default:
          return state
      }
    },
    {
      image: <LottieAnimation animation={sendInformationLottie} />,
      heading: <TranslateHTML id="obrigado.sendInformation.heading" />,
      description: <TranslateHTML id="obrigado.sendInformation.description" />,
      buttons: (
        <>
          <Botao
            sx={{
              width: ["100%", "auto"],
              py: 3,
            }}
            onClick={() => dispatchStep({ type: "form" })}
          >
            <Translate id="obrigado.sendInformation.buttonSend" />
          </Botao>
          <Botao
            onClick={() => dispatchStep({ type: "newsletter" })}
            variant="terciario"
            sx={{
              width: ["100%", "auto"],
              py: 3,
            }}
          >
            <Translate id="obrigado.sendInformation.buttonSkip" />
          </Botao>
        </>
      ),
    }
  )

  return (
    <BaseLayout
      isDark
      hideFooter
      hideHeaderButtons
      hideMenu
      hideFooterRequestBudget
    >
      <SEO {...metatags} />
      <ThankYou {...step} />
    </BaseLayout>
  )
}

export default ObrigadoPage
