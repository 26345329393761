import React from "react"
import * as Style from "./ThankYou.styles"

function ThankYou({
  image = null,
  heading = null,
  description = null,
  buttons = null,
  footer = null,
}) {
  return (
    <Style.CenteredContainer>
      {image && <Style.ImageWrapper>{image}</Style.ImageWrapper>}
      {heading && <Style.Heading>{heading}</Style.Heading>}
      {description && <Style.Description>{description}</Style.Description>}
      {buttons && <Style.ButtonsWrapper>{buttons}</Style.ButtonsWrapper>}
      {footer && <Style.FooterWrapper>{footer}</Style.FooterWrapper>}
    </Style.CenteredContainer>
  )
}

export default ThankYou
